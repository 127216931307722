import IAuthService from "../interfaces/IAuthService";
import httpClient from "@/utils/httpClient";
import cookie from "vue-cookie";

export default class ApiAuthService extends IAuthService {
    async login(userId, password) {
        try {
            const authResponse = await httpClient.post(
                "users/list",
                {},
                {
                    headers: {
                        Authorization: "Basic " + btoa(`${userId}:${password}`),
                    },
                }
            );

            console.log("authResponse", authResponse);

            if (authResponse.status === 200 && authResponse.data.rcode === 1) {
                // Cek apakah userId cocok dengan salah satu email di rows
                const userFound = authResponse.data.rows.some(
                    (user) => user.email === userId
                );

                if (userFound) {
                    // Simpan data user yang login
                    const userData = authResponse.data.rows.find(
                        (user) => user.email === userId
                    );
                    cookie.set("token", authResponse.data.data);
                    cookie.set("user", JSON.stringify(userData));

                    return {
                        success: true,
                        data: {
                            ...authResponse.data,
                            user: userData,
                        },
                    };
                }

                cookie.delete("token");
                cookie.delete("user");
                return { success: false, error: "Invalid credentials" };
            }

            cookie.delete("token");
            cookie.delete("user");
            return { success: false, error: "Invalid credentials" };
        } catch (error) {
            cookie.delete("token");
            cookie.delete("user");
            return { success: false, error: error.message };
        }
    }

    async getProfile() {
        try {
            const response = await httpClient.get("/profile");
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getRole() {
        try {
            // Ambil user data dari cookie
            const userData = JSON.parse(cookie.get("user"));
            if (userData) {
                // Tentukan role berdasarkan administrator flag
                const role = userData ? ["Administrator"] : ["Personel"];

                return {
                    success: true,
                    data: {
                        rows: role,
                    },
                };
            }

            return { success: false, error: "User data not found" };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getMenu() {
        try {
            const response = await httpClient.get("/menu");
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getApps() {
        try {
            const response = await httpClient.get("/apps");
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getPrivilege() {
        try {
            const response = await httpClient.get("/privilege");
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async register(params) {
        try {
            const response = await httpClient.post("/register", params);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async registerVerificationCode(params) {
        try {
            const response = await httpClient.post(
                "/register_validate_code",
                params
            );
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async forgotPassword(username) {
        try {
            const response = await httpClient.post(
                `/request_resetpassword?username=${username}`
            );
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async forgotPasswordVerificationCode(employeeId, otpCode, password) {
        try {
            const response = await httpClient.post(
                `reset_password?username=${employeeId}&code=${otpCode}&password=${password}`
            );
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async resetPassword(params) {
        try {
            const response = await httpClient.post("/reset_password", params);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async changePassword(oldpassword, newpassword) {
        try {
            const formData = new FormData();
            formData.append("oldpassword", oldpassword);
            formData.append("newpassword", newpassword);

            const response = await httpClient.post(
                "/changepassword",
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                    },
                }
            );
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    // Implementasi method lainnya...
}
